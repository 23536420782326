<template>
  <div>
    <div class="card no-border">
      <a href="#" class="w-inline-block">
        <img v-bind:src="post.photo" alt class="card-image-head" />
      </a>
      <div class="card-body">
        <a
        v-if="downloadable"
          v-bind:href="download"
          class="button w-inline-block post-download"
          download
          target="_blank"
          v-on:click="track('download')"
        >
          <div>
            Download
            <i class="fas fa-download"></i>
          </div>
        </a>
        <a
          v-bind:href="post.link"
          v-bind:click="track"
          class="button w-inline-block edit"
          target="_blank"
        >
          <div>
            Edit
            <i class="fas fa-paint-brush"></i>
          </div>
        </a>
                <a
          v-bind:href="butterLink"
          class="button w-inline-block post-find"
          target="_blank"
          v-if="superAdmin == true "
        >
          <div>
            Find
            <i class="fas fa-hand-point-left"></i>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    post: Object,
    workspace: String,
    downloadable: Boolean
  },
  methods: {
    track() {
      // eslint-disable-next-line no-undef
      mixpanel.track("Edited Printable", {
        Calendar: false,
        Category: this.Category,
      });
    }
  },
  computed: {
    superAdmin: function () {
      return this.$auth.user["https://theagentnest.com/super_admin"];
    },
    
    butterLink: function () {
      return (
        "https://buttercms.com/content/workspace/12628/list/"+this.workspace+"/entry/" +
        this.post.meta.id
      );
    },
    download: function () {
      var media = this.post.photo;
      media = media.substring(26);
      var extension = ".png";
      var safeTitle = encodeURI(media+ extension);
      var url =
        "https://fs.buttercms.com/content=t:attachment,f:%22" +
        safeTitle +
        "%22/" +
        media;
      return url;
    },
  },
};
</script>

<style  scoped>
.card-body {
  text-align: center;
}
.post-find {
  padding: 5px 10px;
  cursor: pointer;
  float:right;
}
.edit
{
  margin-left: 6px;
  padding: 5px 10px;
}
</style>